import { FormattedMessage } from "react-intl";
import LocaleSelect from "../localeSelect/LocaleSelect";

type FooterProps = {
  termsLink: string;
  faqLink: string;
  supportLink: string;
};

const Footer = ({ termsLink, faqLink, supportLink }: FooterProps) => {
  return (
    <div className="footer-container">
      <div className="footer-links-container">
        <a href={faqLink} target="_blank">
          <FormattedMessage
            id="fghsdfgsdfg345345345dfghsd"
            defaultMessage="FAQ"
          />
        </a>
        <a href={termsLink} target="_blank">
          <FormattedMessage
            id="GGhyjkyds565623szs"
            defaultMessage="Terms and Conditions"
          />
        </a>
        <LocaleSelect />
      </div>
      <p className="footer-text">
        <FormattedMessage
          id="bbnGtiui9886ggg333f$"
          defaultMessage="For Klip program support, contact "
        />
        <a href={`mailto: ${supportLink}`} target="_blank">
          <FormattedMessage
            id="6yyTrtjuin23a@gj"
            defaultMessage="support@klip.app"
          />
        </a>
      </p>
    </div>
  );
};

export default Footer;
