import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { useBackButton } from "./useBackButton";
import { BackArrow } from "../../assets/icon/BackArrow";
import "./BackButton.css";

export const BackButton = () => {
  const { callbacks } = useBackButton();
  return (
    <Button
      className="back-button"
      onClick={() => callbacks.redirectUser()}
      type="ghost"
    >
      <BackArrow />{" "}
      <FormattedMessage
        id="as87da78d87a"
        defaultMessage="Back"
        description={"Back Button Text"}
      />
    </Button>
  );
};
