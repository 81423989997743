export const Translate = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "5px" }}
    >
      <path
        d="M10.7247 12.5584L8.60801 10.4667L8.63301 10.4417C10.083 8.82508 11.1163 6.96675 11.7247 5.00008H14.1663V3.33341H8.33301V1.66675H6.66634V3.33341H0.833008V4.99175H10.1413C9.58301 6.60008 8.69967 8.12508 7.49967 9.45842C6.72467 8.60008 6.08301 7.65841 5.57467 6.66675H3.90801C4.51634 8.02508 5.34967 9.30842 6.39134 10.4667L2.14967 14.6501L3.33301 15.8334L7.49967 11.6667L10.0913 14.2584L10.7247 12.5584ZM15.4163 8.33342H13.7497L9.99967 18.3334H11.6663L12.5997 15.8334H16.558L17.4997 18.3334H19.1663L15.4163 8.33342ZM13.233 14.1667L14.583 10.5584L15.933 14.1667H13.233Z"
        fill="#656274"
      />
    </svg>
  );
};
