export async function sendIreEvent({
  url = `${process?.env.REACT_APP_EVENT_URL}`,
  user,
  event,
  actionTrackerId = 40223,
}: {
  url?: string;
  user: any;
  event: any;
  actionTrackerId: number;
}) {
  const fields = event.fields
    ? event.fields.map((field: { fieldName: string; fieldValue: string }) => ({
        [field.fieldName]: field.fieldValue,
      }))
    : [{ [event.fieldName]: event.fieldValue }];

  console.log({ fields });

  const props = {
    actionTrackerId: `${actionTrackerId}`,
    eventTypeId: event.key,
    customerId: user.email,
    customerEmail: user.shaEmail,
    orderId: crypto.randomUUID(),
  };

  const ireProps = Object.assign({}, props, ...fields);

  return await window.ire("track", event.key, ireProps, {
    pageUrl: url + "?impactDebugger=1",
    verifySiteDefinitionMatch: true,
  });
}
